export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Products</h2>
          <p>

          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`}
                     // className='col-md-4'
                >
                  {' '}
                  {d.icon? <i className={d.icon}></i> : <img src={d.img} alt="My Logo" height="40" />}

                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                    <a style={{color: 'white'}} href={d.link}>{d.link}</a>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
